export namespace Orson {

    export class Orson {

        // Callbacks the calling code can set to receive data from the server.
        onAudioData: ((audioData: Int16Array) => void) | null = null;
        onFaceData: ((w: number, h: number, data: Float32Array) => void) | null = null;
        onTimingData: ((num_words: number, indices: Uint32Array, times: Float32Array) => void) | null = null;
        onBlendNames: ((json: any) => void) | null = null;

        // Flags.
        public verbose = false;

        // Constants.
        private SERVER_URL = "wss://api.orson.vtime.net/stream";
        private RECONNECT_INTERVAL = 5000;
        private API_KEY = "W7o0cHHowwY4XZXZZQiyA7WrjBvxG1ca";

        // WebSocket connection to the server.
        private connection: WebSocket;

        // Flag for whether we are ready and the string of what to say (if the string is set before we are connected
        // then we'll start the text processing as soon as we connect, otherwise it'll be processed right away).
        private ready = false;
        private speak_text: string = "";

        // The blend name data, maybe received before caller has registered listener, so we keep it here.
        private blendNames: any = null;

        // As data comes in from the server we'll queue it here.
        private incomingQueue = new Int8Array();

        // When we want a specific amount of data, we can use readChunk to request it, it'll set the amount and callback below.
        private readChunkCallback: ((buffer: Uint32Array) => void) | null = null;
        private waitingForLength: number = 1;

        // For our process message loop.
        private processMessageIntervalId: number | null = null;
        private processingMessage = false;

        private onopen(ev: Event): any {
            console.log("[Orson]: Successfully connected to the Orson websocket server 😁");
            this.ready = true;
            if (this.speak_text != "") {
                this.speak(this.speak_text);
                this.speak_text = "";
            }
            return true;
        }

        private onmessage(message: MessageEvent): any {
            const self = this;
            if (message.data) {
                if (message.data instanceof Blob) {
                    const fileReader = new FileReader();
                    fileReader.onload = function onFileReaderLoad(event) {
                        if (!event || !event.target) {
                            return;
                        }

                        const arrayBuffer = event.target.result as ArrayBuffer;
                        const int8Array = new Int8Array(arrayBuffer);

                        self.incomingQueue = Int8Array.from([...self.incomingQueue, ...int8Array]);

                        self.processQueue();
                    }
                    fileReader.readAsArrayBuffer(message.data);
                } else if (typeof message.data === 'string') {
                    const headers = JSON.parse(message.data);
                    if (self.onBlendNames)
                        self.onBlendNames(headers);
                    else
                        self.blendNames = headers;
                } else {
                    console.log(`[Orson]: The data received isn't a Blob or a String 😢`);
                }
            } else {
                console.log('[Orson]: No data in message 😢', message);
            }
            return false;
        }

        private onclose(ev: CloseEvent): any {
            this.ready = false;
            // Try to reconnect in 5 seconds
            console.log(`[Orson]: The connection has been closed 😢 (will reconnect in ${this.RECONNECT_INTERVAL}ms)`, ev);
            const self = this;
            setTimeout(function () {
                self.createWebSocket()
            }, self.RECONNECT_INTERVAL);
        }

        private processQueue(): void {
            if (this.onBlendNames && this.blendNames) {
                this.onBlendNames(this.blendNames);
                this.blendNames = null;
            }
            if (this.waitingForLength == -1) {
                return;
            }
            if (this.incomingQueue.length < this.waitingForLength) {
                return;
            }

            const chunk = new Uint32Array(this.incomingQueue.slice(0, this.waitingForLength).buffer);
            this.incomingQueue = this.incomingQueue.slice(this.waitingForLength);
            this.waitingForLength = -1;
            const readCb = this.readChunkCallback;
            this.readChunkCallback = null;

            // Do this last, as this function may call processQueue again.
            readCb?.(chunk);
        }

        private createWebSocket(): WebSocket {
            console.log("[Orson]: Creating a new Websocket connection... ✅");
            this.connection = new WebSocket(this.SERVER_URL);
            const self = this;
            this.connection.onopen = function (evt) {
                self.onopen(evt);
            }
            this.connection.onmessage = function (evt) {
                self.onmessage(evt);
            };
            this.connection.onclose = function (evt) {
                self.onclose(evt);
            }
            return this.connection;
        }

        private processFrame() {
            if (this.processingMessage)
                return;
            this.processingMessage = true;

            const self = this;
            return new Promise<void>((resolve, reject) => {
                // Frame header.
                self.readChunk("frame header", 24, (buffer: Uint32Array) => {
                    const job_id = buffer[0];
                    const sequence_id = buffer[1];
                    const finished_flag = buffer[2];
                    const num_audio_bytes = buffer[3];
                    const num_face_bytes = buffer[4];
                    const num_timing_bytes = buffer[5];

                    if (this.verbose)
                        console.log(`job_id: ${job_id}, seq_id: ${sequence_id}, fin: ${finished_flag}, #abytes: ${num_audio_bytes}, #fbytes: ${num_face_bytes}, #tbytes: ${num_timing_bytes}}`);

                    if (finished_flag)
                        reject();
                    else
                        resolve();
                });
            }).then(() => {
                // Audio.
                return new Promise<void>((resolve, reject) => {
                    self.readChunk("audio header", 4, (buffer: Uint32Array) => {
                        const audioSamples = buffer[0];
                        const newAudioBytes = audioSamples * 2;

                        if (newAudioBytes > 0) {
                            // Pay load.
                            self.readChunk("audio data payload", newAudioBytes, (buffer: Uint32Array) => {
                                const audio = new Int16Array(buffer.buffer);
                                self.onAudioData?.(audio);
                                resolve();
                            })
                        } else {
                            resolve();
                        }
                    })
                });
            }).then(() => {
                // Face data.
                return new Promise<void>((resolve, _reject) => {
                    self.readChunk("face data header", 8, (buffer) => {
                        const w = buffer[0];
                        const h = buffer[1];
                        console.log(`w: ${w}, h: ${h}`);

                        const bytesBytes = w * h * 4;
                        if (bytesBytes > 0) {
                            // Payload.
                            self.readChunk("facae data payload", bytesBytes, (buffer: Uint32Array) => {
                                const face_data = new Float32Array(buffer.buffer);
                                self.onFaceData?.(w, h, face_data);
                                resolve();
                            });
                        } else {
                            resolve();
                        }

                    });
                });
            }).then(() => {
                // Timing data.
                return new Promise<void>((resolve, reject) => {
                    self.readChunk("timing header", 4, function (timingData) {
                        const num_words = timingData[0];
                        const num_bytes = num_words * 8;

                        if (num_bytes > 0) {
                            // Payload.
                            self.readChunk("timing payload", num_bytes, function (timingData) {
                                const indices = new Uint32Array(timingData.filter((_v, i) => i % 2 == 0).buffer);
                                const timings = new Float32Array(timingData.filter((_v, i) => i % 2 == 1).buffer);
                                self.onTimingData?.(num_words, indices, timings);
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    });
                });
            }).then(() => {
            }).catch(() => {
            }).finally(() => {
                self.processingMessage = false;
            });
        }

        private readChunk(label: string, size: number, callback: (buffer: Uint32Array) => void) {
            if (this.verbose)
                console.log(`[Orson]: Requesting: ${size} bytes for ${label}`);
            this.readChunkCallback = callback;
            this.waitingForLength = size;
            this.processQueue();
        }

        constructor() {
            this.connection = this.createWebSocket();
            const self = this;
            if (this.processMessageIntervalId) {
                clearInterval(this.processMessageIntervalId);
                this.processMessageIntervalId = null;
            }
            this.processMessageIntervalId = window.setInterval(function () {
                self.processFrame();
            }, 10);
        }

        public speak(text: string, voice_id: number = 0) {
            if (this.ready) {
                this.connection.send(JSON.stringify({ text: text, voice: voice_id, apiKey: this.API_KEY, fragment: 4096 }));
            } else {
                this.speak_text = text;
            }
        }
    }

    export const create = function (): Orson {
        const orson = new Orson();
        return orson;
    };
}
