export namespace Carpenter {

    // Status of a request.
    export enum Status {
        NotFound,
        Queued,
        Started,
        Finished,
        Unknown
    }

    // The action being performed by the server for a given asset.
    export enum Action {
        Create,
        Retrieve,
        Invalid
    }

    // The type of a given asset.
    export enum AssetType {
        Location,
        Object,
        Skybox,
        Invalid
    }

    // Definition of an asset.
    export interface Asset {
        action: Action;
        asset_type: AssetType;
        description: string;
        asset_id: string;
        url: string;
        seconds_to_generate: number;
    }

    // Definition of a response (from a request).
    export interface Response {
        session_id: string;
        query: string;
        assets: Array<Asset>;
    }

    // Definition of the details for an asset.
    export interface AssetDetails {
        prompt: string;
        asset_id: string;
        asset_type: string;
        urls: Record<string, string>;
    }

    export class Carpenter {

        private sessionId: string = "";

        public makeRequest(request: string) {
            const self = this;
            return new Promise<Response>(function(resolve, reject) {
                console.log("Requesting: " + request);
                const req = new XMLHttpRequest();
                req.addEventListener("load", function() {
                    console.log("Received response: " + this.responseText);
                    if (req.status == 200) {
                        let json: any;
                        try {
                            json = JSON.parse(this.responseText);
                        } catch(e) {
                            reject(e);
                            return;
                        }
                        
                        // Make sure we have required fields at top level.
                        let missingFields = ['session-id', 'query', 'assets'].filter((k) => {
                            return !json.hasOwnProperty(k);
                        });
                        if (missingFields.length > 0) {
                            reject(`Invalid JSON, does not have ${missingFields.join(", ")} fields`);
                            return;
                        }
                        
                        let assets: Array<Asset> = [];
                        json['assets'].forEach((element: any) => {
                            // Make sure this asset has required fields.
                            let missingFields = ['action', 'asset-type', 'description', 'asset-id', 'url', 'seconds-to-generate'].filter((k) => {
                                return !element.hasOwnProperty(k);
                            });
                            if (missingFields.length > 0) {
                                reject(`Invalid JSON, asset does not have ${missingFields.join(", ")} fields`);
                                return;
                            }
                            
                            const asset: Asset = { 
                                action: (() => {
                                    switch (element['action']) {
                                        case 'create' : return Action.Create;
                                        case 'retrieve' : return Action.Retrieve;
                                        default : return Action.Invalid;
                                    } 
                                })(),
                                asset_type: (() => {
                                    switch (element['asset-type']) {
                                        case 'location' : return AssetType.Location;
                                        case 'object' : return AssetType.Object;
                                        case 'skybox' : return AssetType.Skybox;
                                        default : return AssetType.Invalid;
                                    };
                                })(),
                                description: element['description'], 
                                asset_id: element['asset-id'], 
                                url: element['url'], 
                                seconds_to_generate: element['seconds-to-generate'] 
                            };
                            if (asset.action == Action.Invalid) {
                                reject(`Invalid JSON, asset has an unknown action: ${element['action']}`);
                                return;
                            }
                            if (asset.asset_type == AssetType.Invalid) {
                                reject(`Invalid JSON, asset has an unknown asset-type: ${element['asset-type']}`);
                                return;
                            }
                            assets.push(asset);
                        });
                        resolve({ session_id: json['session-id'], query: json['query'], assets: assets });
                    } else {
                        reject(`HTTP Status: ${req.status}`);
                    }
                });
                req.open("POST", "https://carpenter.internal.vtime.net");
                req.setRequestHeader("Content-Type", "application/json");
                req.send('{"session-id": "' + self.sessionId + '", "query": "' + request + '", "debug": false}');
            });
        }
    
        public onStatusChanged(asset: Asset, status_changed: (status: Status) => void) {
            // For retrieval, the status will likely be not_found as this is an old request, so just tell caller that it has finished.
            if (asset.action == Action.Retrieve) {
                status_changed(Status.Finished);
                return;
            }
    
            // Set polling frequency in milliseconds.
            const POLLING_INTERVAL = 1000;
            
            let done = false;
            let status = Status.Unknown;
            const interval = setInterval(async () => {
                if(done) return;
                const req = await fetch(`https://carpenter.internal.vtime.net/status/${asset.asset_id}`);
                if(done) return;
                if(req.status === 200) {
                    req.text().then((text) => {
                        let new_status = Status.Unknown;
                        switch (text) {
                            case "not_found": {
                                new_status = Status.NotFound;
                            } break;
                            case "queued": {
                                new_status = Status.Queued;
                            } break;
                            case "started": {
                                new_status = Status.Started;
                            } break;
                            case "finished": {
                                new_status = Status.Finished;
                            } break;
                        };
                        if (new_status != status) {
                            status = new_status;
                            status_changed(status);
                        }
                    });
    
                    if (status == Status.NotFound || status == Status.Finished) {
                        done = true;
                        clearInterval(interval);
                    }
                }
            }, POLLING_INTERVAL)
        }
    
        public onFinished(asset: Asset) {
            const self = this;
            return new Promise<Status>(function(resolve, reject) {
                self.onStatusChanged(asset, (status) => {
                    if (status == Status.Finished || status == Status.NotFound) {
                        resolve(status);
                    }
                });
            });
        }
    
        public getAssetDetails(asset: Asset) {
            return new Promise<AssetDetails>(function(resolve, reject) {
                fetch(asset.url).then((response) => {
                    if (response.status == 200) {
                        response.text().then((text) => {
                            let json: any;
                            try {
                                json = JSON.parse(text);
                            } catch(e) {
                                reject(e);
                                return;
                            }
                        
                            // Make sure we have required fields at top level.
                            let missingFields = ['prompt', 'asset-id', 'asset-type', 'urls'].filter((k) => {
                                return !json.hasOwnProperty(k);
                            });
                            if (missingFields.length > 0) {
                                reject(`Invalid JSON, does not have ${missingFields.join(", ")} fields`);
                                return;
                            }
    
                            // Could be simplified to the following, but I don't think it is easier to read.
                            // const urls = Object.assign( {}, ...Object.keys(json['urls']).map(function(key) { return {[key]: json['urls'][key]} }) ) as Record<string, string>;
                            const urls = Object.keys(json['urls']).reduce<Record<string, string>>((acc, cur) => {
                                acc[cur] = json['urls'][cur];
                                return acc;
                            }, {});
    
                            resolve({ prompt: json['prompt'], asset_id: json['asset-id'], asset_type: json['asset-type'], urls: urls });
                        });
                    } else {
                        reject(`HTTP Status: ${response.status}`);
                    }
                });
            });
        }

        public constructor() {
            this.sessionId = Math.random().toString(16).slice(2);
        }
    }

    export const create = function (): Carpenter {
        const carpenter = new Carpenter();
        return carpenter;
    };
}
