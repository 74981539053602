export namespace AudioPlayer {

    export class AudioPlayer {
        private allAudio: Array<Int16Array> = [];
        private _sessionId: number | null = null;                   // used to prevent race conditions between cancel/starts
        private _audioCtx: AudioContext | null = null;              // Created/Closed when this player starts/stops audio
        private _audioSrcNodes: Array<AudioBufferSourceNode> = [];  // Used to fix Safari Bug https://github.com/AnthumChris/fetch-stream-audio/issues/1
        private _totalTimeScheduled = 0.0;                          // time scheduled of all AudioBuffers
        private _playStartedAt = 0;                                 // audioContext.currentTime of first sched
        private _playStartedAtNoDelay = 0;                          // audioContext.currentTime of first sched
        private _abCreated = 0;                                     // AudioBuffers created
        private _abEnded = 0;                                       // AudioBuffers played/ended
        private _skips = 0;                                         // audio skipping caused by slow download
        private verbose = false;
        onFinished: (() => void) | null = null;

        addAudio(audio: Int16Array): void {
            this.allAudio.push(audio);
        }

        get playbackPosition(): number {
            if (this._audioCtx == null) {
                return 0;
            }

            return this._audioCtx.currentTime - this._playStartedAtNoDelay;
        }

        private reset() {
            this.allAudio = [];
            this._sessionId = null;
            this._audioCtx = null;
            this._audioSrcNodes = [];
            this._totalTimeScheduled = 0.0;
            this._playStartedAt = 0;
            this._playStartedAtNoDelay = 0;
            this._abCreated = 0;
            this._abEnded = 0;
            this._skips = 0;
        }

        startAudio(audio_context: AudioContext | null = null) {
            if (!this._sessionId) {
                this._sessionId = performance.now();
                performance.mark(this._downloadMarkKey());
                this._audioCtx = audio_context ?? new (window.AudioContext || window.webkitAudioContext)({ latencyHint: 'interactive' });
            }

            this.startPlayingAudio(this.allAudio);

            this._audioCtx?.resume();
        }

        private _colorLog(msg: string, color: string|null = null) {
            if (this.verbose)
                console.log(`%c[AudioPlayer] ${msg}`, `color: ${color}`);
        }

        private _log(message?: any, ...optionalParams: any[]) {
            if (this.verbose)
                console.log(`[AudioPlayer] ${message}`, ...optionalParams);
        }

        private _downloadMarkKey() {
            return `download-start-${this._sessionId}`;
        }

        private startPlayingAudio(incomingAud: Array<Int16Array>) {
            if (this._audioCtx == null) {
                this._colorLog(`audioCtx is null`, 'red');
                return;
            }
            
            const numOfChannels = 1;
            this._log(`start audio `, incomingAud, this._sessionId, this._audioCtx);

            var aud = incomingAud.shift();
            if (aud != undefined) {
                var audioSrc = this._audioCtx.createBufferSource();
                var audioBuffer = this._audioCtx.createBuffer(numOfChannels, aud.length, 22050);

                audioSrc.onended = () => {
                    this._audioSrcNodes.shift();
                    this._abEnded++;
                    this._colorLog(`Finished playing current buffer ${Date.now()}`, 'orange')
                    this._log(this.allAudio.length, incomingAud.length, this._audioSrcNodes.length);
                    if (this._audioSrcNodes.length === 0) {
                        this._colorLog(`Finished playing all buffers ${Date.now()}`, 'orange');
                        this.reset();
                        this.onFinished?.();
                    }
                }
                this._abCreated++;

                this._audioSrcNodes.push(audioSrc);

                for (let c = 0; c < numOfChannels; c++) {
                    const srcData = this.byteArrayToSamples(aud, aud.length);
                    if (audioBuffer.copyToChannel) {
                        audioBuffer.copyToChannel(srcData, c);
                    } else {
                        let toChannel = audioBuffer.getChannelData(c);
                        for (let i = 0; i < srcData.length; i++) {
                            toChannel[i] = srcData[i];
                        }
                    }
                }

                let startDelay = 0.0;

                if (!this._playStartedAt) {
                    startDelay = 0.1;

                    this._playStartedAt = this._audioCtx.currentTime + startDelay;

                }
                this._playStartedAtNoDelay = this._audioCtx.currentTime;;
                this._colorLog(`Play started at ${this._playStartedAt} current time audio ctx ${this._audioCtx.currentTime} start delay ${startDelay}, total time scheduled ${this._totalTimeScheduled}`, 'pink');

                audioSrc.buffer = audioBuffer;
                audioSrc.connect(this._audioCtx.destination);

                const startAt = this._playStartedAt + this._totalTimeScheduled;
                if (this._audioCtx.currentTime >= startAt) {
                    this._skips++;
                }
                audioSrc.start(startAt);

                this._colorLog(`Audio buffer duration ${audioBuffer.duration} start at ${startAt}`, 'lightgreen');

                this._totalTimeScheduled += audioBuffer.duration;
            }
        }

        private byteArrayToSamples(inputArray: Int16Array, length: number) {
            var output = new Float32Array(length);
            for (var i = 0; i < length; i++) {
                var int = inputArray[i]
                output[i] = int / 32768.0;
            }
            return output
          }
    }

    export const create = function (): AudioPlayer {
        const audio_player = new AudioPlayer();
        return audio_player;
    };
}
