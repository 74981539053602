import { Account } from "./Account";
import { ServerAPI } from "./ServerAPI";

class AccountSession {
    private serverAPI: ServerAPI;
    private account: Account;

    private token: string | null = null;
    private tokenAccountId: string | null = null;
    private tokenExpiry: Date | null = null;

    // When there is less than 5 seconds left, create a new token.
    private static readonly MIN_TOKEN_SECS = 5;

    // Otherwise, if there is less than 60 seconds left, refresh the token.
    private static readonly REFRESH_TOKEN_SECS = 60;

    private verbose = false;

    public setSessionToken(token: string) {
        this.token = token;
    }

    public getSessionToken(): string | null {
        return this.token;
    }

    public sessionTokenValid(): boolean {
        if (this.token)
            return this.token.length > 0;

        return false;
    }

    constructor(serverAPI: ServerAPI, account: Account) {
        this.serverAPI = serverAPI;
        this.account = account;
        this.serverAPI.accountSession = this;
        this.sessionWatcher()
    }

    sessionWatcher() {
        const self = this;

        // Keep track of current token expiration (if we don't have a token, it is now, i.e. we need a new token).
        let expiration = new Date();

        // Stop the function below having multiple versions running whilst promises are waiting to be resolved..
        let processing = false;

        // When to next warn.
        let warnTime: Date = new Date();
        warnTime.setSeconds(warnTime.getSeconds() + 5);

        const interval = setInterval(() => {
            if (processing)
                return;
            processing = true;

            // Lambda to handle obtaining token.
            const obtainToken = (create: boolean) => {
                const action = create ? "create" : "refresh";
                const fn = create ? function (id: string) { return self.serverAPI.PostUserSessionTokenCreate(id); } : function (id: string) { return self.serverAPI.PostUserSessionTokenRefresh(id); };
                if (action === "create") {
                    console.log("Token is invalid or expired, creating new");
                    this.token = "";
                } else {
                    console.log("Token is due to expire, refreshing");
                }
                fn(this.account.getAccountId()).then((response) => {
                    console.log(`Session token ${action}: Expiry ${response.expirySecs} secs`);
                    this.token = response.token;
                    expiration = new Date();
                    expiration.setSeconds(expiration.getSeconds() + response.expirySecs);
                    console.log(`Token expires at ${expiration}`);
                    self.token = response.token;
                    self.tokenAccountId = response.accountID;
                    self.tokenExpiry = new Date();
                    self.tokenExpiry.setSeconds(self.tokenExpiry.getSeconds() + response.expirySecs);
                    processing = false;
                }).catch((reason) => {
                    console.log(`Failed to ${action} token: ${reason}`);
                    processing = false;
                });
            }
            
            if (this.account.getAccountId() == null || this.account.getAccountId() == "" || this.account.getAccountToken() == null || this.account.getAccountToken() == "") {
                if ((new Date()) > warnTime) {
                    console.log("Session token: Waiting for account");
                    warnTime = new Date();
                    warnTime.setSeconds(warnTime.getSeconds() + 5);
                }
                processing = false;
            } else if (!self.tokenIsValid()) {
                obtainToken(true);
            } else if (!self.tokenIsFresh()) {
                obtainToken(false);
            } else {
                processing = false;
            }
        }, 1000);
    }

    private tokenIsFresh(): boolean {
        if (this.token == null) {
            if (this.verbose)
                console.log("No session token");

            return false;
        }

        if (this.account.getAccountId() != this.tokenAccountId) {
            if (this.verbose)
                console.log("Token does not match account ID");

            return false;
        }

        if (this.tokenExpiry) {
            const leftMs = (this.tokenExpiry.getTime() - (new Date()).getTime());
            const leftSec = leftMs / 1000;
            if (leftSec < AccountSession.REFRESH_TOKEN_SECS) {
                if (this.verbose)
                    console.log("Token is due to expire");

                return false;
            }
        }

        return true;
    }

    private tokenIsValid(): boolean {
        if (this.token == null) {
            if (this.verbose)
                console.log("No session token");

            return false;
        }

        if (this.account.getAccountId() != this.tokenAccountId) {
            if (this.verbose)
                console.log("Token does not match account ID");

            return false;
        }

        if (this.tokenExpiry) {
            const leftMs = (this.tokenExpiry.getTime() - (new Date()).getTime());
            const leftSec = leftMs / 1000;
            if (leftSec < AccountSession.MIN_TOKEN_SECS) {
                if (this.verbose)
                    console.log("Token has expired");

                return false;
            }
        }

        return true;
    }

    public waitForAuth(): Promise<void> {
        const self = this;
        return new Promise<void>(function (resolve) {
            const interval = setInterval(() => {
                if (self.tokenIsValid()) {
                    resolve();
                    clearInterval(interval);
                }
            }, 50);
        });
    }
}

export { AccountSession };
