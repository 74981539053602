import { Vector3, Quaternion, float, Angle } from "@babylonjs/core";

class BoneChange {
    public boneName: string = "";
    public posChange: Vector3 = Vector3.Zero();
    public rotChange: Quaternion = Quaternion.Identity();
    public scaleChange: Vector3 = Vector3.Zero();

    public boneIndex = -1;
}

class Deform {
    public name = "";
    public offsets: Array<BoneChange> = new Array<BoneChange>();
}

class DeformList {
    public deforms: Array<Deform> = new Array<Deform>();
}

class Offset {
    public position: Vector3 = Vector3.Zero();
    public euler: Vector3 = Vector3.Zero();
    public scale: Vector3 = Vector3.Zero();

    public Add(positionChange: Vector3, rotationChange: Quaternion, scaleChange: Vector3, t: float): void {
        if (t > 0.0001) {
            this.position.addInPlace(positionChange.scale(t));
            this.euler.addInPlace((Offset.Lerp(Quaternion.Identity(), rotationChange, t)).toEulerAngles());
            this.scale.addInPlace(scaleChange.scale(t));
        }
    }

    public static Lerp(a: Quaternion, b: Quaternion, t: float): Quaternion {
        // negate second quat if dot product is negative
        const l2: float = Quaternion.Dot(a, b);
        if (l2 < 0.0) {
            b = Offset.Negate(b);
        }
        const c: Quaternion = new Quaternion();
        // c = a + t(b - a)  -->   c = a - t(a - b)
        // the latter is slightly better on x64
        c.x = a.x - t * (a.x - b.x);
        c.y = a.y - t * (a.y - b.y);
        c.z = a.z - t * (a.z - b.z);
        c.w = a.w - t * (a.w - b.w);
        return c;
    }

    public static Negate(a: Quaternion): Quaternion {
        return new Quaternion(-a.x, -a.y, -a.z, -a.w);
    }
}

class DeformStackSimple {
    //set of deformations containing all bone changes
    public allDeforms: Array<Deform> = new Array<Deform>();

    //how much of each deform do we apply?
    weights: Array<float> = new Array<float>();
    dirty: boolean = false;

    //current state of the stack
    allOffsets: Array<Offset> = new Array<Offset>();

    //maps which offset is used by a which bone.
    boneNameToIndex: Map<string, number> = new Map<string, number>();

    public LoadDeforms(jsonUrl: string): Promise<void> {
        const self = this;
        return new Promise<void>(function (resolve, reject) {
            fetch(jsonUrl)
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    const dList: DeformList = new DeformList();
                    json["deforms"].forEach(function (deform: any) {
                        const d = new Deform();
                        d.name = deform["name"];
                        deform["offsets"].forEach(function (offset: any) {
                            const boneChange = new BoneChange();
                            boneChange.boneName = offset["boneName"];
                            // Inverse X for RHS to LHS conversion.
                            boneChange.posChange = new Vector3(-offset["posChange"][0], offset["posChange"][1], offset["posChange"][2]);
                            boneChange.rotChange = new Quaternion(offset["rotChange"][0], offset["rotChange"][1], offset["rotChange"][2], offset["rotChange"][3]);
                            // Inverse Z rotation for RHS to LHS conversion.
                            boneChange.rotChange = boneChange.rotChange.toEulerAngles().multiply(new Vector3(1, 1, -1)).toQuaternion();
                            boneChange.scaleChange = new Vector3(offset["scaleChange"][0], offset["scaleChange"][1], offset["scaleChange"][2]);
                            d.offsets.push(boneChange);
                        });
                        dList.deforms.push(d);
                    });
                    return dList;
                }).then(function (dList: DeformList) {
                    if (dList.deforms.length > 0) {
                        //Cache deforms, create weights array
                        self.allDeforms = dList.deforms;
                        self.weights = new Array<float>(self.allDeforms.length);
                        for (var i = 0; i < self.weights.length; i++) {
                            self.weights[i] = 0.0;
                        }

                        //Collect all bones used by the deform list  
                        const uniqueBones: Array<string> = new Array<string>();
                        for (var i = 0; i < self.allDeforms.length; i++) {
                            for (var j = 0; j < self.allDeforms[i].offsets.length; j++) {
                                if (!uniqueBones.includes(self.allDeforms[i].offsets[j].boneName)) {
                                    uniqueBones.push(self.allDeforms[i].offsets[j].boneName);
                                }
                            }
                        }

                        //Create bone mapping for output processing
                        self.boneNameToIndex = new Map<string, number>();
                        for (var i = 0; i < uniqueBones.length; i++) {
                            self.boneNameToIndex.set(uniqueBones[i], i);
                        }

                        //Prep the workspace
                        self.allOffsets = new Array<Offset>(uniqueBones.length);

                        for (var i = 0; i < self.allOffsets.length; i++) {
                            self.allOffsets[i] = new Offset();
                        }

                        //Map indices of each bone onto the unique list
                        for (var i = 0; i < self.allDeforms.length; i++) {
                            for (var j = 0; j < self.allDeforms[i].offsets.length; j++) {
                                self.allDeforms[i].offsets[j].boneIndex = uniqueBones.indexOf(self.allDeforms[i].offsets[j].boneName);
                            }
                        }
                        resolve();
                    } else {
                        reject("No deforms found in " + jsonUrl);
                    }
                }).catch(function (error) {
                    reject(error);
                });
        });
    }

    public Update(): void {
        if (this.dirty) {
            //Clearing modifications
            for (var i = 0; i < this.allOffsets.length; i++) {
                // Position.
                this.allOffsets[i].position.x = 0.0;
                this.allOffsets[i].position.y = 0.0;
                this.allOffsets[i].position.z = 0.0;
                // Rotation.
                this.allOffsets[i].euler.x = 0.0;
                this.allOffsets[i].euler.y = 0.0;
                this.allOffsets[i].euler.z = 0.0;
                // Scale.
                this.allOffsets[i].scale.x = 0.0;
                this.allOffsets[i].scale.y = 0.0;
                this.allOffsets[i].scale.z = 0.0;
            }

            for (var i = 0; i < this.allDeforms.length; i++) {
                if (this.weights[i] > 0.0) {
                    //Applying all non-zero deforms
                    const deform: Deform = this.allDeforms[i];
                    for (var j = 0; j < deform.offsets.length; j++) {
                        const change: BoneChange = deform.offsets[j];
                        this.allOffsets[change.boneIndex].Add(change.posChange, change.rotChange, change.scaleChange, this.weights[i]);
                    }
                }
            }

            this.dirty = false;
        }
    }

    public ResetWeights() {
        for (var i = 0; i < this.weights.length; i++) {
            this.weights[i] = 0.0;
        }
        this.dirty = true;
    }

    public GetIndexForBone(name: string): number {
        return this.boneNameToIndex.get(name) ?? -1;
    }

    public SetWeightForDeformIndex(deformIndex: number, weight: float): void {
        if (deformIndex >= 0 && deformIndex < this.allDeforms.length) {
            this.weights[deformIndex] = weight;
            this.dirty = true;
        }
    }

    public SetWeightForDeformName(deformName: string, weight: float): void {
        const index: number = this.GetDeformIndex(deformName);
        this.SetWeightForDeformIndex(index, weight);
    }

    public GetWeightForDeformIndex(deformIndex: number): float {
        if (deformIndex >= 0 && deformIndex < this.allDeforms.length) {
            return this.weights[deformIndex];
        }

        return 0.0;
    }

    public GetWeightForDeformName(deformName: string): float {
        const index: number = this.GetDeformIndex(deformName);
        return this.GetWeightForDeformIndex(index);
    }

    public GetDeformIndex(deformName: string): number {
        for (var i = 0; i < this.allDeforms.length; i++) {
            if (this.allDeforms[i].name == deformName) {
                return i;
            }
        }

        return -1;
    }

    public GetOffsetForBoneIndex(boneIndex: number): Offset | null {
        if (boneIndex >= 0 && boneIndex < this.allOffsets.length) {
            return this.allOffsets[boneIndex];
        }

        return null;
    }

    public GetOffsetForBoneName(boneName: string): Offset | null {
        const index = this.GetIndexForBone(boneName);
        return this.GetOffsetForBoneIndex(index);
    }
}

export { DeformStackSimple, Offset };