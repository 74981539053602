import { Account } from "./Account/Account";
import { AccountSession } from "./Account/AccountSession";
import { ServerAPI } from "./Account/ServerAPI";

class BotAPI {
    private serverAPI: ServerAPI;
    private account: Account;
    private accountSession: AccountSession;

    private static readonly APP_ID = "kinder-scout";

    constructor(serverAPI: ServerAPI, account: Account, accountSession: AccountSession) {
        this.serverAPI = serverAPI;
        this.account = account;
        this.accountSession = accountSession;

        accountSession.waitForAuth().then(() => {
            console.log("Connecting to BotAPI...");
    
            serverAPI.Post<BotState>(`https://bot-api.alpha.vtime.net/state/${account.getAccountId()}`, { appID: BotAPI.APP_ID, archetypeID: "vicky" }).then((bot_state) => {
                console.log(bot_state);
    
                const endpoint = `wss://bot-api.alpha.vtime.net/stream`;
                const uri = `${endpoint}/${account.getAccountId()}/${bot_state.botID}?appID=${BotAPI.APP_ID}&wsh=vTime-Session%3A${accountSession.getSessionToken()}`;
                console.log(uri);
    
                const RECONNECT_INTERVAL = 5000;
    
                console.log("[BotAPI]: Creating a new Websocket connection... ✅");
                let connection = new WebSocket(uri);
                connection.onopen = function (evt) {
                    console.log("[BotAPI]: Successfully connected to the BotAPI websocket server 😁");
                }
                connection.onerror = function (evt) {
                    console.log("[BotAPI]: An error occurred with the connection to the BotAPI websocket server 😢");
                }
                connection.onmessage = function (evt) {
                    console.log("[BotAPI]: Received a message from the BotAPI websocket server 📨");
                    console.log(evt.data);
                };
                connection.onclose = function (evt) {
                    console.log(`[BotAPI]: The connection has been closed 😢 (will reconnect in ${RECONNECT_INTERVAL}ms)`, evt);
                }
            });
        });        
    }
}

export { BotAPI };