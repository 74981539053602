import plugin_vue3_A0OWXRrUgq from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import buffer_aHHLuY3sF2 from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/plugins/buffer.ts";
import vuetify_7h9QAQEssH from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/plugins/vuetify.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  buffer_aHHLuY3sF2,
  vuetify_7h9QAQEssH
]