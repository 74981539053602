import { default as indexIGBz17aCqNMeta } from "/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/pages/index.vue?macro=true";
export default [
  {
    name: indexIGBz17aCqNMeta?.name ?? "index",
    path: indexIGBz17aCqNMeta?.path ?? "/",
    meta: indexIGBz17aCqNMeta || {},
    alias: indexIGBz17aCqNMeta?.alias || [],
    redirect: indexIGBz17aCqNMeta?.redirect || undefined,
    component: () => import("/mnt/jenkins/builds/DHrAzyPk/0/web-apps/carpenter-web/pages/index.vue").then(m => m.default || m)
  }
]