import { Vector3 } from "@babylonjs/core";
import { DeformStackSimple, Offset } from "./DeformerStack";

class OrsonFaceDataPoint {
    frame: number = -1;
    offsets: Array<Offset> = new Array<Offset>();
}

class OrsonFaceData {
    data: Float32Array = new Float32Array();
    h: number = 0;
    blendNames: string[] = [];
    deformerStack: DeformStackSimple;
    bones = Array<string>();
    targets = Array<number>();

    p0: OrsonFaceDataPoint = new OrsonFaceDataPoint();
    p1: OrsonFaceDataPoint = new OrsonFaceDataPoint();

    get frameRate(): number {
        return 30.0;

    }
    get durationInFrames(): number {
        return this.h == 0 ? 0 : this.data.length / this.h;
    }

    get durationInSeconds(): number {
        return this.durationInFrames / this.frameRate;
    }

    constructor(deformerStack: DeformStackSimple) {
        this.deformerStack = deformerStack;
    }

    reset() {
        this.data = new Float32Array();
        this.h = 0;

        this.p0 = new OrsonFaceDataPoint();
        this.p1 = new OrsonFaceDataPoint();
    }

    setBlendNames(names: string[]) {
        this.blendNames = names;
    }

    setBones(bones: Array<string>) {
        this.bones = bones;
    }

    setTargets(targets: Array<number>) {
        this.targets = targets;
    }

    addData(data: Float32Array, h: number) {
        if (this.h == 0) {
            this.h = h;
        } else if (this.h != h) {
            console.error("Orson face data has inconsistent number of blends");
        }
        const firstLength = this.data.length,
            result = new Float32Array(firstLength + data.length);

        result.set(this.data);
        result.set(data, firstLength);

        this.data = result;
    }

    getBlends(t: number): Array<Offset> {
        if (this.durationInFrames == 0) {
            return [];
        }

        const first = Math.floor(t * 30) % this.durationInFrames;
        const second = (first + 1) % this.durationInFrames;
        if (this.p0.frame != first) {
            this.deformerStack.ResetWeights();
            for (var i = 0; i < this.blendNames.length; i++) {
                this.deformerStack.SetWeightForDeformName(this.blendNames[i], this.data[first * this.h + i]);
            }
            this.deformerStack.Update();
            this.p0.frame = first;
            this.p0.offsets = [...this.deformerStack.allOffsets];

            this.deformerStack.ResetWeights();
            for (var i = 0; i < this.blendNames.length; i++) {
                this.deformerStack.SetWeightForDeformName(this.blendNames[i], this.data[second * this.h + i]);
            }
            this.deformerStack.Update();
            this.p1.frame = second;
            this.p1.offsets = [...this.deformerStack.allOffsets];
        }

        const firstSec = first / this.frameRate;
        // Don't use 'second' as it may have wrapped.
        const secondSec = firstSec + (1.0 / this.frameRate);
        const durationSec = secondSec - firstSec;
        const tSec = t % this.durationInSeconds;

        const t2: number = ((tSec - firstSec) / (durationSec));

        const result = new Array<Offset>(this.deformerStack.allOffsets.length);

        for (var i = 0; i < this.deformerStack.allOffsets.length; i++) {
            result[i] = new Offset();
            result[i].position = Vector3.Lerp(this.p0.offsets[i].position, this.p1.offsets[i].position, t2);
            result[i].euler = Vector3.Lerp(this.p0.offsets[i].euler, this.p1.offsets[i].euler, t2);
            result[i].scale = Vector3.Lerp(this.p0.offsets[i].scale, this.p1.offsets[i].scale, t2);
        }

        return result;
    }
}

export { OrsonFaceData, OrsonFaceDataPoint };
