import {
    Engine, Scene, Vector3, AbstractMesh, GroundMesh, Color3, HemisphericLight, PointLight, Ray, MeshBuilder, Mesh, Node, Nullable, FreeCamera, Camera, WebXRDefaultExperience,
    ArcRotateCamera, Color4, SceneLoader, CubeTexture, AudioEngine, ISceneLoaderAsyncResult, WebXRSessionManager, WebXRExperienceHelper, WebXRState, KeyboardEventTypes, RayHelper, AnimationGroup, ISceneLoaderProgressEvent, HDRCubeTexture, BackgroundMaterial, Texture, AssetContainer, EquiRectangularCubeTexture, Vector4, StandardMaterial, Sound, Quaternion, Bone, Angle, Tools, AxesViewer, TransformNode
} from "@babylonjs/core";
import { AdvancedDynamicTexture, StackPanel, Control, TextBlock, Slider } from "@babylonjs/gui";
import { GLTF2Export, IExportOptions } from "@babylonjs/serializers";
import "@babylonjs/loaders/glTF";
import JSZip, { JSZipObject } from "jszip";
import { LocationDatabase } from "./LocationDatabase";
import { WebXRCamera } from "@babylonjs/core/XR/webXRCamera";
import { Carpenter } from "./carpenter";
import { Orson } from "./orson";
import { AudioPlayer } from "./AudioPlayer";
import { ServerAPI } from "./Account/ServerAPI";
import { Account } from "./Account/Account";
import { AccountSession } from "./Account/AccountSession";
import { BotAPI } from "./BotAPI";
import { DeformStackSimple, Offset } from "./DeformerStack";
import { getAnimationList } from "./AnimationList";
import { EyeLidFixer } from "./EyeLidFixer";
import { OrsonFaceData } from "./OrsonFaceData";

class Avatar {
    // The visuals representation of the model.
    public model: AssetContainer | null = null;
    public modelPromise: Promise<void | ISceneLoaderAsyncResult> | null = null;

    // List of animations that can be played.
    public static readonly ANIMATION_GROUP_BODY = 0;
    public static readonly ANIMATION_GROUP_FACE = 1;
    public static readonly ANIMATION_GROUP_NUM = 2;
    public animationList: Array<AnimationGroup> = new Array<AnimationGroup>(Avatar.ANIMATION_GROUP_NUM);

    // The animation data (loaded from file) applied to the model.
    public animations: Array<AssetContainer | null> = new Array<AssetContainer>(Avatar.ANIMATION_GROUP_NUM);
    public animationPromises: Array<Promise<void | AssetContainer>> = new Array<Promise<void | AssetContainer>>(Avatar.ANIMATION_GROUP_NUM);
}

let globalEngine: Engine;
let globalScene: Scene;
let globalAudio: AudioEngine;
let globalCamera: Camera;
let globalXR: WebXRDefaultExperience;
let sceneAssetContainer: AssetContainer | null = null;
let pumpkinRootMesh: AbstractMesh | null = null;
let avatar: Avatar;
enum FlatScreenCameraMode {
    ArcRotate,
    FreeCam
}
let flatScreenCameraMode: FlatScreenCameraMode = FlatScreenCameraMode.FreeCam;
let inVRMode: boolean = false;
let objectAssetContainers: Array<AssetContainer> = [];
let globalSkybox: Mesh | null = null;
let globalSkyboxMaterial: StandardMaterial | null = null;
let selectedAnimation: number = 0;

const locationDatabase: LocationDatabase = new LocationDatabase();

let timeUntilNextSceneLoadCheck = -1;
const SCENE_LOAD_CHECK_FREQUENCY = 5000;

const carpenter = Carpenter.create();

const deformStackSimple = new DeformStackSimple();

const setupVRCamera = function (canvas: HTMLCanvasElement) {
    globalCamera?.detachControl();
    const freeCam: FreeCamera = new FreeCamera("camera1", new Vector3(0, 5, -10), globalScene);
    freeCam.setTarget(Vector3.Zero());
    freeCam.attachControl(canvas, true);
    globalCamera = freeCam;
    //globalScene.activeCamera = globalCamera;

    pointCameraAtVicky();
}

const setupFlatscreenCamera = function (canvas: HTMLCanvasElement, fromVrMode: boolean) {
    globalCamera?.detachControl();
    switch (flatScreenCameraMode) {
        case FlatScreenCameraMode.ArcRotate: {
            const arcRotateCamera: ArcRotateCamera = new ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2.5, 3, new Vector3(0, 1, 0));
            if (globalCamera) {
                const freeCam: FreeCamera = globalCamera as FreeCamera;
                arcRotateCamera.target = freeCam.getTarget();
                arcRotateCamera.position = freeCam.position;
            }
            arcRotateCamera.wheelPrecision = 100;
            arcRotateCamera.minZ = 0.01;
            globalCamera = arcRotateCamera;
            break;
        }
        case FlatScreenCameraMode.FreeCam: {
            const freeCam: FreeCamera = new FreeCamera("camera", new Vector3(0, 1.25, -2.5), globalScene);
            if (globalCamera) {
                freeCam.position = globalCamera.position.clone();
                freeCam.setTarget((globalCamera as ArcRotateCamera).getTarget());
            }
            freeCam.keysUpward.push(69); //increase elevation
            freeCam.keysDownward.push(81); //decrease elevation
            freeCam.keysUp.push(87); //forwards 
            freeCam.keysDown.push(83); //backwards
            freeCam.keysLeft.push(65);
            freeCam.keysRight.push(68);
            freeCam.speed = 0.1;
            freeCam.minZ = 0.01;
            globalCamera = freeCam;
            break;
        }
    }
    globalCamera.attachControl(canvas, true);
    globalScene.activeCamera = globalCamera;
    // If we are switching from VR mode to flat screen, we don't want to clone VR camera position, just point at Vicky.
    if (fromVrMode)
        pointCameraAtVicky();
}

const dumpLoadedMeshInfo = function (name: string, result: AssetContainer) {
    result.meshes.forEach((mesh) => {
        console.log(`[${name}] Loaded: ${mesh.name} with ${mesh.animations.length} animations`)
    });
    result.animationGroups.forEach((animationGroup) => {
        console.log(`[${name}] Loaded: ${animationGroup.name} with ${animationGroup.animatables.length} animtables`)
    });
    result.skeletons.forEach((skeleton) => {
        console.log(`[${name}] Loaded: ${skeleton.name} with ${skeleton.bones.length} bones`)
    });
}

const loadSkybox = function (skyboxUrl: string) {
    return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest();
        req.addEventListener("load", function () {
            if (this.status == 200) {
                console.log("Downloaded: " + skyboxUrl);
                var new_zip = new JSZip();
                new_zip.loadAsync(this.response).then(async function (zip) {
                    // Attempt to get the six directional images from the zip file.
                    const images = ["pos_x", "pos_y", "pos_z", "neg_x", "neg_y", "neg_z"]
                        .map(e => zip.file(`${e}.jpg`))
                        .filter((e): e is NonNullable<JSZipObject> => !!e);

                    // Ensure we have all six images.
                    if (images.length == 6) {
                        if (!globalSkybox || !globalSkybox.material) {
                            reject("Invalid skybox");
                            return;
                        }
                        // Asynchronously download the six images.
                        const array_buffers_promises = images.map(e => e.async("arraybuffer"));
                        // Wait for all six images to download.
                        Promise.all(array_buffers_promises).then((array_buffers) => {
                            // Get the URLs for the six images.
                            const urls = array_buffers.map(e => URL.createObjectURL(new Blob([e])));
                            // Create a new cube texture from the six images.
                            const tex = new CubeTexture(skyboxUrl, globalScene, undefined, undefined, urls, () => {
                                if (!globalSkybox || !globalSkyboxMaterial) {
                                    reject("No skybox");
                                    return;
                                } else {
                                    tex.coordinatesMode = Texture.SKYBOX_MODE;
                                    globalSkyboxMaterial.reflectionTexture = tex;
                                    resolve(globalSkybox.material);
                                }
                            }, (errorMessage) => {
                                console.log("Failed to load: " + errorMessage);
                                reject(errorMessage);
                            }, undefined, false, ".jpg");
                        }).catch((reason) => {
                            reject(reason);
                        });
                    } else {
                        reject("Didn't find 6 cubemap images");
                    }
                }).catch((reason) => {
                    // Failed to load zip file.
                    reject(reason);
                })
            } else {
                // Failed to download zip file.
                console.log("Status: " + this.status);
                reject("HTTP code: " + this.status);
            }
        });
        req.responseType = "arraybuffer";
        req.open("GET", skyboxUrl);
        req.send();
    });
}

const serverAPI: ServerAPI = new ServerAPI();
const account: Account = new Account(serverAPI);
const accountSession: AccountSession = new AccountSession(serverAPI, account);
const botAPI: BotAPI = new BotAPI(serverAPI, account, accountSession);

const targetToDeform: Array<number> = new Array<number>();


const orsonFaceData: OrsonFaceData = new OrsonFaceData(deformStackSimple);
const ap = AudioPlayer.create();
const orson = Orson.create();

const createScene = async function (canvas: HTMLCanvasElement, model: string) {
    account.createAccount();

    globalEngine = new Engine(canvas);
    globalScene = new Scene(globalEngine);
    globalAudio = new AudioEngine();

    setupFlatscreenCamera(canvas, false);

    const isLocalhost = (location.hostname === "localhost" || location.hostname === "127.0.0.1");
    const isSecure = location.protocol === 'https:';
    const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
    if (isChrome && (isSecure || isLocalhost)) {
        globalScene.createDefaultXRExperienceAsync({
            disablePointerSelection: true,
            disableTeleportation: true,
            disableNearInteraction: true,
            floorMeshes: undefined,
            outputCanvasOptions: { canvasElement: canvas as HTMLCanvasElement }
        }).then((xr) => {
            globalXR = xr;
            globalXR.baseExperience.onStateChangedObservable.add((state) => {
                switch (state) {
                    case WebXRState.IN_XR:
                        if (globalScene.activeCameras == null) {
                            globalScene.activeCamera = null;
                        }
                        if (globalScene.cameras.length == 0) {
                            globalScene.activeCameras = [globalXR.baseExperience.camera, new FreeCamera("camera", new Vector3(0, 1.25, -2.5), globalScene)];
                        }
                        console.log(globalScene.cameras);
                        break;
                    case WebXRState.ENTERING_XR:
                        inVRMode = true;
                        setupVRCamera(canvas);
                        break;
                    case WebXRState.EXITING_XR:
                        inVRMode = false;
                        setupFlatscreenCamera(canvas, true);
                        break;
                    case WebXRState.NOT_IN_XR:
                        break;
                }
            });
        });
    }
    globalScene.onKeyboardObservable.add((kbInfo) => {
        switch (kbInfo.type) {
            case KeyboardEventTypes.KEYDOWN:
                if (kbInfo.event.code.localeCompare("KeyC") == 0) {
                    const vals = Object.values(FlatScreenCameraMode).filter((x) => { return Number.isInteger(x) });
                    flatScreenCameraMode = vals[(flatScreenCameraMode.valueOf() + 1) % vals.length] as FlatScreenCameraMode;
                    setupFlatscreenCamera(canvas, false);
                }
                break;
        }
    });

    globalScene.onAfterRenderCameraObservable.add((camera) => {
        if (camera instanceof WebXRCamera) {
            // reset the dimensions object for correct resizing
            globalScene.getEngine().framebufferDimensionsObject = null;
        }
    });

    const spotlight = new PointLight("spotLight", new Vector3(-1, 5, 1), globalScene);
    const light = new HemisphericLight("light", new Vector3(0, 1, 0), globalScene);
    const audio = Engine.audioEngine as AudioEngine;
    // const ground = MeshBuilder.CreateGround("ground", {width: 1.4, height: 1.4}, globalScene);

    // Create a default skybox with an environment.
    var hdrTexture = new CubeTexture("https://d30uvc41ku0n03.cloudfront.net/v1", globalScene, ["_px.png", "_py.png", "_pz.png", "_nx.png", "_ny.png", "_nz.png"]);
    globalSkybox = globalScene.createDefaultSkybox(hdrTexture, false);
    globalSkyboxMaterial = globalSkybox?.material as StandardMaterial
    // globalSkybox = Mesh.CreateBox("skyBox", 1000, globalScene);
    // globalSkybox.isPickable = false;
    // globalSkyboxMaterial = new StandardMaterial("skyBoxMaterial", globalScene);
    // globalSkyboxMaterial.backFaceCulling = false;
    // globalSkyboxMaterial.reflectionTexture = new CubeTexture("https://d30uvc41ku0n03.cloudfront.net/v1", globalScene, [ "_px.png", "_py.png", "_pz.png", "_nx.png", "_ny.png", "_nz.png" ]);
    // globalSkyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    // globalSkyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    // globalSkyboxMaterial.specularColor = new Color3(0, 0, 0);
    // globalSkyboxMaterial.disableLighting = true;
    // globalSkybox.material = globalSkyboxMaterial;

    // Avatar stuff.
    avatar = new Avatar();

    // Tricia.
    //let triciaPromise = SceneLoader.ImportMeshAsync("", "https://skiddaw-content.s3.eu-west-2.amazonaws.com/b/d/bdb57ff44251533b93c509ce02eb69f0.glb",).then((result) => {
    //let triciaPromise = SceneLoader.ImportMeshAsync("", "avatar/output.GLB",).then((assetContainer) => {
    avatar.modelPromise = SceneLoader.LoadAssetContainerAsync("", "avatar/VickyDef.txt_idlePose.glb",).then((assetContainer) => {
        // Make her face us.
        assetContainer.meshes[0].rotate(new Vector3(0, 1, 0), Math.PI);
        assetContainer.meshes[0].position.x += 2;
        assetContainer.addAllToScene();
        avatar.model = assetContainer;
        //dumpLoadedMeshInfo("Tricia", assetContainer);
        pointCameraAtVicky();
    });

    // Load a face animation.
    //loadAvatarAnimation(`talking/CLEM_Result_MsgToGLB_faceAnimOnlyAllbones.glb`, Avatar.ANIMATION_GROUP_FACE, true, true);

    // Load a body animation.
    //loadAvatarAnimationPreset(getCurrentAnimation());
    //loadAvatarAnimation(`avatar/Animations/Anim_fran_Victory.glb`);

    // Pumpkin.
    SceneLoader.ImportMeshAsync("", model).then((result) => {
        result.meshes[0].position.x -= 2;
        result.meshes[0].position.y += 0.4;
        result.meshes[0].scaling = new Vector3(0.5, 0.5, 0.5);
        pumpkinRootMesh = result.meshes[0];
    });

    // Deformer test.
    deformStackSimple.LoadDeforms("deformer/AvatarFaceDeforms.json").then(() => {
        console.log("Loaded AvatarFaceDeforms.json");
        Promise.all([avatar.modelPromise, ...avatar.animationPromises]).then(() => {
            if (avatar.model) {
                const model: AssetContainer = avatar.model;


                const allBones: Array<Bone> = new Array<Bone>();

                const tPosePositions: Array<Vector3> = new Array<Vector3>();
                const tPoseRotations: Array<Quaternion> = new Array<Quaternion>();
                const tPoseScales: Array<Vector3> = new Array<Vector3>();

                model.skeletons.forEach((skeleton) => {
                    skeleton.bones.forEach((bone) => {
                        if (allBones.filter(b => b.name === bone.name).length == 0) {
                            allBones.push(bone);

                            // tPosePositions.push(bone.getTransformNode()!.position.clone()!);
                            // tPoseRotations.push(bone.getTransformNode()!.rotationQuaternion!.clone()!);
                            // tPoseScales.push(bone.getTransformNode()!.scaling.clone()!);
                        }
                    })
                });

                const targetToDeform = new Array<number>(allBones.length);
                for (var i = 0; i < allBones.length; i++) {
                    targetToDeform[i] = deformStackSimple.GetIndexForBone(allBones[i].name);
                }

                orsonFaceData.setBones(allBones.map(bone => bone.name));
                orsonFaceData.setTargets(targetToDeform);

                const eyeLidFixers = [
                    new EyeLidFixer(allBones.find(b => b.name === "EyeLidUp_L")?.getTransformNode() ?? null, allBones.find(b => b.name === "EyeLidLow_L")?.getTransformNode() ?? null),
                    new EyeLidFixer(allBones.find(b => b.name === "EyeLidUp_R")?.getTransformNode() ?? null, allBones.find(b => b.name === "EyeLidLow_R")?.getTransformNode() ?? null),
                ]

                globalScene.registerBeforeRender(() => {
                    const blends = orsonFaceData.getBlends(ap.playbackPosition);

                    if (blends.length > 0) {
                        for (var i = 0; i < allBones.length; i += 1) {
                            if (targetToDeform[i] != -1) {
                                const offset = blends[targetToDeform[i]];
                                if (offset) {
                                    const transformNode = allBones[i].getTransformNode();
                                    if (transformNode) {
                                        // Apply translation, no flipping needed - done whilst loading.
                                        transformNode.position.addInPlace(new Vector3(offset.position.x, offset.position.y, offset.position.z));

                                        // Apply rotation, no flipping needed - done whilst loading.
                                        transformNode.rotationQuaternion!.multiplyInPlace(Quaternion.FromEulerAngles(offset.euler.x, offset.euler.y, offset.euler.z));

                                        // Apply scale, no flipping needed.
                                        transformNode.scaling.addInPlace(offset.scale);
                                    }
                                }
                            }
                        }
                        eyeLidFixers.forEach(f => f.Fix());
                    }
                });
            }

            if (false) {
                drawBlendShapeSliders();
            }
        });
    });

    // Orson.
    ap.onFinished = () => {
    };

    orson.onAudioData = (data) => {
        console.log(`Received ${data.length} bytes of audio data`);
        ap.addAudio(data);
        ap.startAudio(globalAudio.audioContext);
    };
    orson.onFaceData = (w, h, data) => {
        console.log(`Received ${w}x${h} face data with ${data.length} bytes`);
        orsonFaceData.addData(data, h);
    };
    orson.onTimingData = (num_words, indices, timings) => {
        console.log(`Received ${num_words} words of timing data`);
        for (let i = 0; i < num_words; i++) {
            console.log(`Word ${indices[i]}: ${timings[i]}s`);
        }
    };
    orson.onBlendNames = (json: any) => {
        console.log(`Blends: fps: ${json.animationFps}, #blends: ${json.blendNames.length}`);
        orsonFaceData.setBlendNames(json.blendNames);
    };

    editSpotLight(spotlight);
    editHemisphericLight(light);
    editAudio(audio);
    // editGround(ground);

    // ground.material = floorMaterial;

    globalScene.clearColor = new Color4(0, 0, 0, 0);

    globalEngine.runRenderLoop(function () {
        globalScene.render();
        checkLoadSceneRequests(globalEngine.getDeltaTime());
        let divFps: Nullable<HTMLElement> = document.getElementById("fps")!;
        divFps.innerHTML = globalEngine.getFps().toFixed() + " fps";
    })

    window.addEventListener("resize", function () {
        globalEngine.resize();
    });

    // Save scene after 10 seconds.
    // window.setTimeout(function() {
    //     saveScene([currentSkybox!], true);
    // }, 10000);


    loadSceneIfChanged(globalScene).finally(() => {
        enableSceneLoadCheck();
    });
};

const drawBlendShapeSliders = function () {
    // GUI
    var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

    var parentPanel = new StackPanel();
    parentPanel.isVertical = false;
    parentPanel.width = "500px";
    parentPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    parentPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    advancedTexture.addControl(parentPanel);

    var panelLeft = new StackPanel();
    panelLeft.width = "250px";
    panelLeft.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    panelLeft.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    parentPanel.addControl(panelLeft);

    var panelRight = new StackPanel();
    panelRight.width = "250px";
    panelRight.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    panelRight.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    parentPanel.addControl(panelRight);

    const data = [
        { deforms: deformStackSimple.allDeforms.slice(0, deformStackSimple.allDeforms.length / 2), panel: panelLeft },
        { deforms: deformStackSimple.allDeforms.slice(deformStackSimple.allDeforms.length / 2), panel: panelRight },
    ]

    data.forEach((d) => {
        d.deforms.forEach((deform) => {
            var header = new TextBlock();
            header.text = deform.name + " 0%";
            header.height = "15px";
            header.color = "white";
            d.panel.addControl(header);

            var slider = new Slider();
            slider.minimum = 0;
            slider.maximum = 1;
            slider.value = 0;
            slider.height = "15px";
            slider.width = "250px";
            slider.onValueChangedObservable.add(function (value) {
                header.text = deform.name + " " + value * 100 + "%";
                deformStackSimple.SetWeightForDeformName(deform.name, value);
            });
            d.panel.addControl(slider);
        });
    });
}

const loadAvatarAnimation = function (filename: string, bodyPart: number, faceUs: boolean, additive: boolean) {
    const promises = avatar.animationPromises[bodyPart] != null ? [avatar.animationPromises[bodyPart]] : [];
    Promise.all(promises).then(() => {
        avatar.animations[bodyPart]?.removeAllFromScene();
        avatar.animations[bodyPart] = null;

        avatar.animationPromises[bodyPart] = SceneLoader.LoadAssetContainerAsync("", filename).then((assetContainer) => {
            if (faceUs) {
                // Make animation face us.
                assetContainer.meshes[0].rotate(new Vector3(0, 1, 0), Math.PI);
            }
            avatar.animations[bodyPart] = assetContainer;
            //dumpLoadedMeshInfo("Vicky", assetContainer);

            Promise.all([avatar.modelPromise]).then(() => {
                console.log("Both meshes loaded");

                if (avatar.model) {
                    avatar.animationList[bodyPart]?.stop();
                    let armatureMeshParent: Node = avatar.model.meshes[0];
                    let lastValid: Node;

                    assetContainer.animationGroups.forEach((ag) => {
                        let group = ag.clone(ag.name, (target) => {
                            const n = armatureMeshParent.getChildren((node) => node.name === target.name, false);
                            if (n && n.length == 1) {
                                lastValid = n[0];
                                return n;
                            } else {
                                return lastValid;
                            }
                        });
                        avatar.animationList[bodyPart] = group;
                    });

                    // It seems we have to stop and start all animations, otherwise starting the current animation will stop previous
                    // ones (and just starting previous ones isn't enough, you have to start them first).
                    avatar.animationList.forEach((ag) => {
                        ag?.stop();
                        ag?.start(true, undefined, undefined, undefined, additive);
                    });
                }
            });
        });
    });
}
const loadAvatarAnimationPreset = function (anim: string) {
    loadAvatarAnimation(`avatar/Animations/Anim_${anim}.glb`, Avatar.ANIMATION_GROUP_BODY, true, false);
}

const saveScene = function (meshesToExclude: [Mesh], exportAnimations: boolean) {
    console.log("Saving");
    let options: IExportOptions = {
        shouldExportNode: (node) => {
            return !meshesToExclude.includes(node as Mesh);
        },
        shouldExportAnimation: (animation) => {
            return exportAnimations;
        }
    }

    GLTF2Export.GLBAsync(globalScene, "scene", options).then((glb) => {
        glb.downloadFiles();
    });
}

const disableSceneLoadCheck = function () {
    timeUntilNextSceneLoadCheck = -1;
}

const enableSceneLoadCheck = function () {
    timeUntilNextSceneLoadCheck = 0;
}

const isSceneLoadCheckEnabled = function (): boolean {
    return timeUntilNextSceneLoadCheck >= 0;
}
const shouldDoSceneLoadCheck = function (): boolean {
    return timeUntilNextSceneLoadCheck >= SCENE_LOAD_CHECK_FREQUENCY;
}
const forceSceneLoadCheck = function () {
    timeUntilNextSceneLoadCheck = SCENE_LOAD_CHECK_FREQUENCY;
}

const checkLoadSceneRequests = function (deltaTimeMs: number) {
    if (isSceneLoadCheckEnabled()) {
        timeUntilNextSceneLoadCheck += globalEngine.getDeltaTime();
        if (shouldDoSceneLoadCheck()) {
            disableSceneLoadCheck()
            loadSceneIfChanged(globalScene).finally(() => {
                enableSceneLoadCheck();
            });
        }
    }
}
const pointCameraAtVicky = function () {
    if (!avatar.model)
        return;

    avatar.model.meshes[0].computeWorldMatrix(true);

    // If we have an arc/rotate camera (not a VR free camera).
    if (inVRMode && globalXR) {
        globalXR.baseExperience.camera.position = avatar.model.meshes[0].position.add(new Vector3(0, 1.25, -2.5));
    }
    else if (globalCamera instanceof ArcRotateCamera) {
        const arcRotateCamera: ArcRotateCamera = globalCamera as ArcRotateCamera;
        arcRotateCamera.setTarget(avatar.model.meshes[0].position.add(new Vector3(0, 1.0, 0)), undefined, false, true);
    } else if (globalCamera instanceof FreeCamera) {
        const freeCamera: FreeCamera = globalCamera as FreeCamera;
        freeCamera.position = avatar.model.meshes[0].position.add(new Vector3(0, 1.25, -2.5));
        freeCamera.rotation = new Vector3(0, 0, 0);
    }
}

const repositionObjectsOnFloor = function (scene: Scene, repositionCamera: boolean) {
    const rayStart = new Vector3(0, -50, 0);
    const ray = new Ray(rayStart, new Vector3(0, 1, 0), 100);
    const hit = scene.pickWithRay(ray, (mesh) => {
        return mesh.isEnabled() && mesh.isVisible && mesh.isPickable && (!sceneAssetContainer || sceneAssetContainer.meshes.includes(mesh));
    });
    if (hit?.hit && hit.pickedPoint) {
        console.log(`Repositioning Vicky and Pumpkin after hitting: ${hit.pickedMesh?.name} at ${hit.pickedPoint}`);
        const floor = new Vector3(0, hit.pickedPoint.y, 0);
        avatar.animations.filter((e): e is NonNullable<AssetContainer> => !!e).forEach((anim) => {
            anim.meshes[0].position.y = floor.y;
        });
        if (avatar.model) {
            avatar.model.meshes[0].position.y = floor.y;
        }
        if (pumpkinRootMesh) {
            pumpkinRootMesh.position.y = floor.y + 0.4;
        }
        objectAssetContainers.forEach((container) => {
            container.meshes[0].position.y += floor.y - container.meshes[0].getHierarchyBoundingVectors(true).min.y;
        });

        if (repositionCamera)
            pointCameraAtVicky();
    }
}

const loadRoom = function (resolve: (value: unknown) => void, reject: (reason?: any) => void, scene: Scene) {
    let meshCountBeforeLoad = scene.meshes.length;
    loadGlbFromZip(locationDatabase.getSceneUrl(), scene).then((container) => {
        let meshesAdded = scene.meshes.length - meshCountBeforeLoad;
        if (sceneAssetContainer) {
            let meshCount = scene.meshes.length;
            sceneAssetContainer.removeAllFromScene();
            console.log("Removed " + (meshCount - scene.meshes.length) + " old meshes");
            sceneAssetContainer = null;
            locationDatabase.setAllLoadedFlags(false);
        }
        container.addAllToScene();
        sceneAssetContainer = container;
        container.meshes[0].addRotation(-Math.PI / 2, 0, Math.PI / 2);
        container.meshes[0].scaling = new Vector3(-3, 3, 3);
        container.meshes[0].position.y += 3;

        // Force update ahead of raycast.
        container.meshes.forEach(mesh => mesh.computeWorldMatrix(true));

        repositionObjectsOnFloor(globalScene, true);

        console.log("Room loaded " + meshesAdded + " meshes");
        locationDatabase.setLoaded(true);

        resolve(container.meshes[0]);
    }).catch((message) => {
        console.log("Couldn't load room: " + message);
        resolve(message);
    });
}

const loadSceneIfChanged = function (scene: Scene) {
    return new Promise(function (resolve, reject) {
        if (locationDatabase.isLoaded()) {
            resolve("No scene to load");
            return;
        }

        if (locationDatabase.getSceneUrl() == null || locationDatabase.getSceneUrl().length == 0) {
            if (locationDatabase.getAsset() != null) {
                carpenter.getAssetDetails(locationDatabase.getAsset()!).then((assetDetails) => {
                    locationDatabase.setSceneUrl(assetDetails.urls['glb']);
                    loadRoom(resolve, reject, scene);
                }).catch(e => {
                    reject(e);
                });
            } else {
                reject("No asset to load");
            }
        } else {
            loadRoom(resolve, reject, scene);
        }
    });
}

const setupAudio = function () {

    if (Engine.audioEngine) {
        Engine.audioEngine.useCustomUnlockedButton = true
    } else {
        alert("No audio engine detected")
    }
}

const download = function (buffer: ArrayBufferLike, filename: string) {
    var blob = new Blob([buffer]);
    const a: HTMLAnchorElement = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const url: string = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = filename;

    a.click();

    window.URL.revokeObjectURL(url);
    a.parentElement?.removeChild(a);
}

const playScene = function () {
    // console.log("Playing animation")
    // globalScene.animationGroups.forEach(element =>
    //     element.start()
    // )

    // // Say something.
    // orson.speak("The quick brown fox jumped over the lazy fox!");
}

const resetScene = function () {

    // Engine.audioEngine?.unlock()

    // globalScene.animationGroups.forEach(element =>
    //     element.reset()
    // )
}

const stopScene = function () {
    // console.log("Pausing animation")
    // globalScene.animationGroups.forEach(element =>
    //     element.stop()
    // )
}

const speakSend = function (text: string) {
    orsonFaceData.reset();
    orson.speak(text);
}

const textSend = function (text: string, locations: string[], updateLocationFn: (newLocation: string) => void) {
    if (locationDatabase.isLoaded()) {
        carpenter.makeRequest(text).then((response) => {
            if (response.assets.length > 0) {

                // Objects - wait for them all to finish loading, then remove old objects and replace with new.
                const objects = response.assets.filter((asset) => {
                    return asset.asset_type == Carpenter.AssetType.Object;
                });
                if (objects.length > 0) {
                    const object_promises = objects.map((asset) => { return carpenter.onFinished(asset) });
                    Promise.all<Promise<Carpenter.Status>>(object_promises).then((statuses) => {
                        const finished = statuses.filter((status) => { return status == Carpenter.Status.Finished; });
                        if (finished.length > 0) {
                            objectAssetContainers.forEach((container) => {
                                container.removeAllFromScene();
                            });
                            objectAssetContainers = [];

                            let assetDetailsPromises: Array<Promise<void>> = [];
                            let glbPromises: Array<Promise<void>> = [];
                            objects.forEach((asset, ix) => {
                                assetDetailsPromises.push(carpenter.getAssetDetails(asset).then((assetDetails) => {
                                    glbPromises.push(loadGlbFromZip(assetDetails.urls['glb'], globalScene).then((container) => {
                                        //result.meshes[0].addRotation(-Math.PI / 2, 0, Math.PI / 2);
                                        container.addAllToScene();
                                        container.meshes[0].scaling = new Vector3(-1, 1, 1);
                                        container.meshes[0].position.x += 3 - (1.5 * (ix%6));
                                        container.meshes[0].position.z += 2 - Math.floor(ix/6);
                                        const rot = container.meshes[0].rotationQuaternion!.toEulerAngles();
                                        rot.x -= Math.PI / 2;
                                        container.meshes[0].rotationQuaternion = Quaternion.FromEulerAngles(rot.x, rot.y, rot.z);;
                                        objectAssetContainers.push(container);

                                        // Force update ahead of raycast.
                                        container.meshes.forEach(mesh => mesh.computeWorldMatrix(true));
                                    }));
                                }));
                            });
                            Promise.all(assetDetailsPromises).then(() => {
                                Promise.all(glbPromises).then(() => {
                                    repositionObjectsOnFloor(globalScene, false);
                                });
                            });
                        }
                    });
                }

                // Locations/skyboxes, if mutliple are returned just load the first from each category.
                const locationAssets = response.assets.filter(o => o.asset_type == Carpenter.AssetType.Location);
                const skyboxAssets = response.assets.filter(o => o.asset_type == Carpenter.AssetType.Skybox);

                [locationAssets, skyboxAssets].forEach((assets) => {
                    if (assets.length > 0) {
                        const asset = assets[0];
                        carpenter.onStatusChanged(asset, (status) => {
                            console.log(`Status: ${Carpenter.Status[status]}`);
                            if (status == Carpenter.Status.Finished) {
                                switch (asset.asset_type) {
                                    case Carpenter.AssetType.Location: {
                                        locationDatabase.add(asset, true);
                                        locations.push(locationDatabase.getDescription());
                                        updateLocationFn(locationDatabase.getDescription());
                                        console.log("Will load when ready : " + locationDatabase.getId());
                                    } break;

                                    case Carpenter.AssetType.Skybox: {
                                        console.log("Attempting to load: " + asset.url);
                                        carpenter.getAssetDetails(asset).then((assetDetails) => {
                                            loadSkybox(assetDetails.urls['cubemap']);
                                        });
                                    } break;

                                    default: {
                                        console.log("Ignoring unknown asset-type");
                                    } break;
                                }
                            }
                        });
                    }
                });
            }
        }).catch((error) => {
            console.log("Error from Carpenter: " + error);
        });
    } else {
        console.log(`Ignoring: ${text} as we are currently loading a scene`);
    }
}

const getExistingDescriptions = function (): Array<string> {
    return locationDatabase.getAllDescriptions();
}

const getCurrentDescription = function (): string {
    return locationDatabase.getDescription();
}

const locationChanged = function (location: string) {
    locationDatabase.setActiveItem(location);
    forceSceneLoadCheck();
}

const getCurrentAnimation = function (): string {
    return getAnimationList()[selectedAnimation];
}

const animationChanged = function (anim: string) {
    loadAvatarAnimationPreset(anim);
}

const loadModel = function (response: any, name: string, scene: Scene): Promise<AssetContainer> {
    const assetBlob = new Blob([response]);
    return SceneLoader.LoadAssetContainerAsync("", new File([assetBlob], name), scene, undefined, ".glb");
}

const loadGlbFromZip = function (url: string, scene: Scene): Promise<AssetContainer> {
    console.log("Trying to load GLB from: " + url);
    return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest();
        req.addEventListener("load", function () {
            if (this.status == 200) {
                console.log("Downloaded: " + url);
                var new_zip = new JSZip();
                new_zip.loadAsync(this.response)
                    .then(function (zip) {
                        const firstGlb = zip.filter(path => path.endsWith(".glb")).at(0);
                        if (firstGlb) {
                            firstGlb.async("arraybuffer").then(function (buffer) {
                                console.log("Loading: " + firstGlb.name);
                                resolve(loadModel(buffer, url + "/" + firstGlb.name, scene));
                            });
                        } else {
                            console.log("No GLB files found in archive");
                            if (reject) {
                                reject("File not found");
                            }
                        }
                    });
            } else {
                reject("Status: " + this.status);
            }
        });
        req.responseType = "arraybuffer";
        req.open("GET", url);
        req.send();
    });
}


const playClip = function () {
    globalScene.animationsEnabled = true
    Engine.audioEngine?.unlock()
}

const editAudio = function (audio: AudioEngine) {
    audio.setGlobalVolume(1.0);
}

const editGround = function (ground: GroundMesh) {
    ground.receiveShadows = true;
}

const editSpotLight = function (spotlight: PointLight) {

    spotlight.diffuse = new Color3(0, 0, 0)
    spotlight.specular = new Color3(0, 0, 0);
    //spotlight.groundColor = new Color3(0, 0, 0);

    spotlight.intensity = 0;
}

const editHemisphericLight = function (light: HemisphericLight) {

    light.diffuse = new Color3(0.8, 0.8, 0.8);
    light.intensity = 0.8;
}

export { createScene, playScene, resetScene, stopScene, speakSend, textSend, getExistingDescriptions, getCurrentDescription, locationChanged, getAnimationList, getCurrentAnimation, animationChanged };