import { Carpenter } from "./carpenter";

class LocationDatabase {
    private existing: { loaded: boolean, description: string, id: string, jsonUrl: string, sceneUrl: string, asset: Carpenter.Asset|null}[]  = [
        LocationDatabase.entry("Vicky's room generated by Andrew Webb", "vicky-003"),
        LocationDatabase.entry("room with interior of a McDonald's at a kids party", "80ac7826f9535193585681879e09ed9d"),
        LocationDatabase.entry("room with interior of a Burger King at a kids party", "4663b2fef36ba6a8fcdd01879e0e7cd2"),
        LocationDatabase.entry("room with interior of a house from The Simpsons", "ed737bbd7c86b1a6fb35e1879e1172b8"),
        LocationDatabase.entry("room with interior of a house from South Park cartoon", "ce7ca73435d119243b3521879e1547c9"),
        LocationDatabase.entry("room with interior made from folded paper napkins with biro doodles of cartoon cats and dogs", "7af4462e85a3892a850451879e1f3911"),
        LocationDatabase.entry("room with interior of a luxury yacht cabin suitable for royalty", "be5e78376cee994cb0f0b1879ea2859f"),
        LocationDatabase.entry("room that looks like a traditional irish pub", "9da1699ae5869c98f2ddb187a3245c56"),
        LocationDatabase.entry("room with interior of a nightmare inducing vision of hell", "02d453817f1d9d2365b04187a3859f6f"),
        LocationDatabase.entry("room with interior of an expensive Malibu mansion", "da8989d95ecd391912d5c187a388ae3e"),
        LocationDatabase.entry("room with interior of a Chinese tea shop", "6e75b2aea46de4e4529fb187a4b89e8b"),
        LocationDatabase.entry("room that looks like an 80s office block", "e671ad72c7dc6a3be6e2e187b7e36416"),
        LocationDatabase.entry("make a room that looks like black and white steam boat willie tom and jerry", "fa0e72c80817f8d5b6e23187c32a489a"),
        {loaded: false, description: "room with exposed brickwork, portraits on the wall, an open fire and ornate detailed wallpaper", id: "964106c3278d00f4e563d18848d9cf9f", jsonUrl: "https://minio-api.internal.vtime.net/ml-carpenter.vtime.net/assets/room/964106c3278d00f4e563d18848d9cf9f.json", sceneUrl: "https://minio-api.internal.vtime.net/ml-carpenter.vtime.net/assets/room/964106c3278d00f4e563d18848d9cf9f.glb.zip", asset: null},
        {loaded: false, description: "room that looks like the inside of a limousine", id: "e589be192906f77d8d51d18810805575", jsonUrl: "https://minio-api.internal.vtime.net/ml-carpenter.vtime.net/assets/room/e589be192906f77d8d51d18810805575.json", sceneUrl: "https://minio-api.internal.vtime.net/ml-carpenter.vtime.net/assets/room/79bbf37199936c4aeb6c418810750243.glb.zip", asset: null},
    ];

    // Make an entry from a description and ID.
    static entry(description: string, id: string) {
        return {loaded: false, description: description, id: id, jsonUrl: "", sceneUrl: this.makeOldUrl(id), asset: null};
    }

    // Before migrating to new system, this is how we derived URLs from an ID.
    static makeOldUrl(id: string) {
        if (location.protocol === 'https:') {
            return "https://minio-api.internal.vtime.net/carpenter-content-test/room/" + id + ".zip";
        } else {
            return "http://minio.internal.vtime.net:9000/carpenter-content-test/room/" + id + ".zip";
        }
    }

    // Current selected item.
    private selection: number = 0;

    // Accessors.
    isLoaded(): boolean {
        return this.existing[this.selection].loaded;
    }

    getAsset() : Carpenter.Asset|null {
        return this.existing[this.selection].asset;
    }

    getId() : string {
        return this.existing[this.selection].id;
    }

    getSceneUrl() : string {
        return this.existing[this.selection].sceneUrl;
    }

    getJsonUrl() : string {
        return this.existing[this.selection].jsonUrl;
    }

    getDescription() : string {
        return this.existing[this.selection].description;
    }

    setLoaded(loaded: boolean) {
        this.existing[this.selection].loaded = loaded;
    }

    setSceneUrl(url: string) {
        this.existing[this.selection].sceneUrl = url;
    }

    getAllDescriptions(): Array<string> {
        return this.existing.reduce( function(obj, item) { obj.push(item.description); return obj }, new Array<string>);
    }

    setAllLoadedFlags(loaded: boolean) {
        this.existing.forEach((item) => item.loaded = loaded);
    }

    add(asset: Carpenter.Asset, make_active: boolean): number {
        this.existing.push({loaded: false, description: asset.description, id: asset.asset_id, jsonUrl: asset.url, sceneUrl: "", asset});
        const ix = this.existing.length - 1;
        if (make_active)
            this.selection = ix;
        return ix;
    }

    setActiveItem(description: string) {
        this.selection = this.existing.findIndex((item) => {return item.description.localeCompare(description) == 0});
    }
}

export { LocationDatabase };