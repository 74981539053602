const getAnimationList = function (): Array<string> {
    return [
        "BaddersDance",
        "Badders_Jumping",
        "dancer_Dandruff",
        "dancer_JumpHappy",
        "dancer_Renegade",
        "dancer_Scarecrow",
        "dancer_SeanPaul",
        "dan_Dance_ChopAir",
        "dan_Driving",
        "fran_HandsBack",
        "fran_HandsHead",
        "fran_Standing",
        "fran_Victory",
        "hal_Frankenstein",
        "hal_Witch",
        "hal_ZombieWalk",
        "Hollywood_Stretch_01",
        "Hollywood_Talk_Low_energy",
        "Idle_Static",
        "Idle_Stepping",
        "miguel_HipHop_Baked",
        "nye_Charleston",
        "nye_Clapping_Low",
        "nye_HeelClick",
        "obi_Air_Guitar_Baked",
        "obi_Boxing_Baked",
        "obi_Floss_Dance_Baked",
        "obi_OverHere_Baked",
        "obi_Showing_Left_Baked",
        "obi_Talking_Bow_Baked",
        "obi_Talking_Calm_Baked",
        "obi_ThumbsUp_Baked",
        "Take_001",
        "TalkingAnimation",
        "vicky_CatwalkPoses",
        "vicky_HeartHands",
        "xmas_santaLaugh",
        "xmas_singingCarol",
        "Yoga_Warrior"
    ];
}

export { getAnimationList };