import { Angle, Quaternion, TransformNode } from "@babylonjs/core";

class EyeLidFixer {
    public COMBINED_EYELID_LIMIT = Angle.FromDegrees(62.0).radians();

    public upper: TransformNode | null = null;
    public lower: TransformNode | null = null;

    public bias = 0.6;

    constructor(upper: TransformNode | null, lower: TransformNode | null) {
        this.upper = upper;
        this.lower = lower;
    }

    public Fix(): void {
        if (!this.upper || !this.lower || !this.upper.rotationQuaternion || !this.lower.rotationQuaternion) {
            return;
        }

        //Upper
        const upperRot = this.upper.rotationQuaternion.toEulerAngles();
        const upperZ = EyeLidFixer.Wrap180(upperRot.z);

        //Lower
        const lowerRot = this.lower.rotationQuaternion.toEulerAngles();
        const lowerZ = EyeLidFixer.Wrap180(lowerRot.z);

        //calculate total diff from original rotation
        //All eyelids start at initial local rotation of [0,0,0], so we can simply use total of abs(localRotation)
        const totalDiff = Math.abs(upperZ) + Math.abs(lowerZ);

        //calculate how much to relax eyelids per-eye
        const totalReduction = EyeLidFixer.Clamp(totalDiff - this.COMBINED_EYELID_LIMIT, 0.0, Math.PI);

        if (totalReduction > 0.0) {
            //upper eye lid has more movement between all expressions and customisation, so we reduce it more

            //Upper - note we use += here (versus -= in Unity) to account for GLB handedness.
            upperRot.z += totalReduction * this.bias;
            this.upper.rotationQuaternion = Quaternion.FromEulerAngles(upperRot.x, upperRot.y, upperRot.z);

            //Lower - note we use -= here (versus += in Unity) to account for GLB handedness.
            lowerRot.z -= totalReduction * (1.0 - this.bias);
            this.lower.rotationQuaternion = Quaternion.FromEulerAngles(lowerRot.x, lowerRot.y, lowerRot.z);
        }
    }

    static Wrap180(f: number): number {
        if (f > Math.PI) {
            return f - (Math.PI * 2.0);
        }

        if (f < -Math.PI) {
            return f + (Math.PI * 2.0);
        }

        return f;
    }

    static Clamp(f: number, min: number, max: number): number {
        if (f < min) {
            return min;
        }

        if (f > max) {
            return max;
        }

        return f;
    }
}

export { EyeLidFixer };