import { Account } from "./Account";
import { AccountSession } from "./AccountSession";

class ServerAPI {
    // This is a bit messy, these are filled in by Account/AccountSession constructors.
    public account: Account|null = null;
    public accountSession: AccountSession|null = null;
    
    // Which server we point at.
    private static readonly IS_PRODUCTION: boolean = false;

    // API functions.
    public createAccountKey(): Promise<CreateAccountKeyResponse> {
        return this.Post<CreateAccountKeyResponse>(`${ServerAPI.GetServerUrl()}/user/account/key`, {});
    }
    public createAccount(id: string, key: string): Promise<CreateAccountResponse> {
        return this.Post<CreateAccountResponse>(`${ServerAPI.GetServerUrl()}/user/account`, { id: id, key: key });
    }
    public getAccount(accountId: string): Promise<GetAccountResponse> {
        return this.Get<GetAccountResponse>(`${ServerAPI.GetServerUrl()}/user/${accountId}/account/`);
    }
    public PostUserSessionTokenCreate(accountId: string): Promise<SessionTokenResponse> {
        return this.Post<SessionTokenResponse>(`${ServerAPI.GetServerUrl()}/user/${accountId}/session-token/create`, {});
    }
    public PostUserSessionTokenRefresh(accountId: string): Promise<SessionTokenResponse> {
        return this.Post<SessionTokenResponse>(`${ServerAPI.GetServerUrl()}/user/${accountId}/session-token/refresh`, {});
    }

    // HTTP helper methods.
    public Post<T>(url: string, body: any): Promise<T> {
        const self = this;
        return new Promise<any>(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('readystatechange', function () {
                if (xhr.readyState == 4) {
                    if (xhr.status != 200) {
                        console.log(`Error: HTTP Status: ${xhr.status}`);
                        reject(xhr.status);
                    } else {
                        let json: any;
                        try {
                            json = JSON.parse(this.responseText);
                            resolve(json);
                        } catch (e) {
                            reject(e);
                            return;
                        }
                    }
                }
            });
            xhr.open("POST", url);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Accept", "application/json");
            self.setAuth(xhr);
            self.setAgent(xhr);
            xhr.send(JSON.stringify(body));
        });
    }

    public Get<T>(url: string): Promise<T> {
        const self = this;
        return new Promise<any>(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('readystatechange', function () {
                if (xhr.readyState == 4) {
                    if (xhr.status != 200) {
                        console.log(`Error: HTTP Status: ${xhr.status}`);
                        reject(xhr.status);
                    } else {
                        let json: any;
                        try {
                            json = JSON.parse(this.responseText);
                            resolve(json);
                        } catch (e) {
                            reject(e);
                            return;
                        }
                    }
                }
            });
            xhr.open("GET", url);
            xhr.setRequestHeader("Accept", "*/*");
            self.setAuth(xhr);
            self.setAgent(xhr);
            xhr.send();
        });
    }

    // For setting authentication headers.
    private setAuth(xhr: XMLHttpRequest) {
        if (this.accountSession && this.accountSession.sessionTokenValid()) {
            xhr.setRequestHeader("Authorization", ServerAPI.GetSessionAuthPrefix() + this.accountSession.getSessionToken());
        } else if (this.account != null && this.account.accountTokenValid()) {
            xhr.setRequestHeader("Authorization", ServerAPI.GetAccountAuthPrefix() + this.account.getAccountToken());
        }
    }

    // For setting user agent headers.
    private setAgent(xhr: XMLHttpRequest) {
        xhr.setRequestHeader("User-Agent", ServerAPI.GetUserAgent());
        xhr.setRequestHeader("Session-ID", this.account ? this.account.getSessionId() : "");
    }

    public static readonly GetServerUrl = function (): string {
        if (ServerAPI.IS_PRODUCTION)
            return "https://app.vtime.net";
        else
            return "https://dev.vtime.net";
    }

    public static readonly GetAccountAuthPrefix = function (): string {
        if (ServerAPI.IS_PRODUCTION)
            return "vTime ";
        else
            return "MVR ";
    }

    public static readonly GetSessionAuthPrefix = function (): string {
        return "vTime-Session ";
    }

    public static readonly GetUserAgent = function (): string {
        const productId = "Carpenter";
        const productVersion = "0.0.1";
        const bundleId = "net.vtime.carpenter.vue";
        const buildId = "b666_r999";
        return `${productId}/${productVersion} (${bundleId}; ${buildId})`;
    }

    public static GetAlphaNumeric(length: number): string {
        let val = "";
        const ALPHANUM = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var buf = new Uint8Array(length);
        crypto.getRandomValues(buf);
        for (var i = 0; i < length; ++i)
            val += ALPHANUM[buf[i] % ALPHANUM.length];
        return val;
    }
}

export { ServerAPI };