import { ServerAPI } from "./ServerAPI";

class Account {
    private serverAPI: ServerAPI;

    private accountId: string = "";
    private accountToken: string = "";
    private sessionId: string = "";

    public setIdToken(id: string, token: string) {
        this.accountId = id;
        this.accountToken = token;
        this.sessionId = ServerAPI.GetAlphaNumeric(32);
    }

    public getAccountId(): string {
        return this.accountId;
    }

    public getAccountToken(): string {
        return this.accountToken;
    }

    public getSessionId(): string {
        return this.sessionId;
    }

    public accountTokenValid() {
        if (this.accountToken)
            return this.accountToken.length > 0;

        return false;
    }

    public createAccount() {
        // We generate our own desired account ID. We currently don't deal with clashes.
        this.accountId = ServerAPI.GetAlphaNumeric(8);

        this.serverAPI.createAccountKey().then((createAccountKeyResponse) => {
            return this.serverAPI.createAccount(this.accountId, createAccountKeyResponse.key);
        }).then((createAccountResponse) => {
            this.setIdToken(createAccountResponse.id, createAccountResponse.token);
            return this.serverAPI.getAccount(this.accountId);
        }).then((getAccountResponse) => {
            console.log(`Account created: ${this.accountId}`);
        }).catch((reason) => {
            console.log(`Couldn't complete flow to get JWT token: ${reason}`)
        });
    }

    constructor(serverAPI: ServerAPI) {
        this.serverAPI = serverAPI;
        this.serverAPI.account = this;
    }
}

export { Account };
